import React, {ReactNode} from "react";

// global style, it's very IMPORTANT that this should load first
// before loading any other component
import "./src/styles/global.css";

export const wrapRootElement = ({element}: {element: ReactNode}) => {
  // useful to wrap the root element with any provider
  return <>{element}</>;
};

export const wrapPageElement = ({
  element,
}: {
  element: ReactNode;
  props: any;
}) => {
  // useful to load any component on every page
  return <>{element}</>;
};

export const onInitialClientRender = () => {
  // do something, like initialize analytics
};

export const shouldUpdateScroll = ({routerProps: {location}}) => {
  // https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
  // always scroll to top on route chage
  if (location.hash) {
    return false;
  }
  window.scrollTo(0, 0);

  return false;
};
